body {
  overflow: hidden;
}

.strong {
  font-weight: 700;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}
.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.object-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.object-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 1.5rem;
}

.mt-3 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: 2rem;
}

.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 1.5rem;
}
.pt-3 {
  padding-top: 3rem;
}

.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 1.5rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pl-3 {
  padding-left: 3rem;
}
.pr-3 {
  padding-right: 3rem;
}
.pr-5 {
  padding-right: 5rem;
}
.font-bold {
  font-weight: bold;
}

.zIndex-1031 {
  z-index: 1031 !important;
}

.ant-message {
  z-index: 1032 !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c3c3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9a9a9a;
}

.grecaptcha-badge {
  /* visibility: hidden; */
}

td.ant-table-cell {
  padding: 0px 16px !important;
  text-align: center;
}

.ant-table-placeholder td.ant-table-cell {
  padding: 16px !important;
}

.table-content-inner .ant-table-body {
  height: calc(100vh - 214px);
}

.table-content-inner.hidden-filter .ant-table-body {
  height: calc(100vh - 165px);
}